<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="ios hydrated container-page-header">
        <div class="page-title">
          <h1>Settings</h1>
        </div>
      </div>
      <form>
        <h2>Change Your Password</h2>
        <ion-grid class="login-form">
          <ion-row>
            <ion-col>
              <ion-item lines="none">
                <ion-label class="standard-label" position="stacked">Old Password</ion-label>
                <ion-input class="standard-input" type="password" name="oldpassword" />
              </ion-item>
              <ion-item lines="none">
                <ion-label class="standard-label" position="stacked">New Password</ion-label>
                <ion-input class="standard-input" type="password" name="password" />
              </ion-item>
              <ion-item lines="none">
                <ion-label class="standard-label" position="stacked">Retype New Password</ion-label>
                <ion-input class="standard-input" type="password" name="retypepassword" />
              </ion-item>
              <div class="button-container">
                <ion-button color="secondary" fill="solid" class="login-btn" type="submit">Save Password</ion-button>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';

export default {
  components: {
    IonContent,
    IonPage,
  },
  data () {
    return {
      form: {
        oldpassword: "",
        newpassword: "",
        passwordretype: ""
      }
    }
  },
}
</script>

<style scoped lang="scss">
h1, h2 {
  text-align: center;
}

.button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
